<template>
  <div
    v-if="pageCount > 1"
    class="pagination"
  >
    <div class="pagination__btn">
      <component
        :is="paginationElement"
        v-if="prev"
        class="pagination__link pagination__prev"
        v-bind="prevLinkAttrs"
        @click="arrowClick('prev')"
      >
        <SvgIconArrowLeft class="pagination__arrow" />
      </component>
    </div>

    <ul
      v-if="pageCount > 1"
      class="pagination__pages"
    >
      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="{
          'pagination__current': page.current,
          'pagination__item--separator': !page.url,
        }"
        class="pagination__item"
      >
        <component
          :is="paginationElement"
          v-if="page.url"
          :to="page.url"
          @click="pageLinkClick(page.index)"
        >
          {{ page.index }}
        </component>

        <span v-else> &hellip; </span>
      </li>
    </ul>

    <div class="pagination__btn">
      <component
        :is="paginationElement"
        v-if="next"
        class="pagination__link pagination__next"
        v-bind="nextLinkAttrs"
        @click="arrowClick('next')"
      >
        <SvgIconArrowRight class="pagination__arrow" />
      </component>
    </div>
  </div>
</template>

<script>
import SvgIconArrowLeft from '~ui/assets/icons/icon-arrow-left.svg'
import SvgIconArrowRight from '~ui/assets/icons/icon-arrow-right.svg'

export default {
  components: { SvgIconArrowLeft, SvgIconArrowRight },
  props: {
    url: {
      type: String,
      required: true,
      default: '/'
    },
    pageCount: {
      type: Number,
      required: true,
      default: 1
    },
    current: {
      type: Number,
      required: true,
      default: 1
    },
    showPages: {
      type: Number,
      default: 5,
      required: false
    },
    showPagesCount: {
      type: Number,
      default: 3,
      required: false,
      validator: (val) => val >= 2
    },
    break: {
      type: Boolean,
      default: false,
      required: false
    },
    paginationType: {
      type: String,
      default: 'actual',
      validator: (val) => ['actual', 'virtual'].includes(val)
    },
    pagePrefix: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    paginationElement() {
      return this.paginationType === 'actual' ? 't3-link' : 'button'
    },
    nextLinkAttrs() {
      return this.paginationType === 'actual' ? { to: this.next } : {}
    },
    prevLinkAttrs() {
      return this.paginationType === 'actual' ? { to: this.prev } : {}
    },
    urlLangPrefix() {
      return this.lang !== 'en' ? `/${this.lang}` : ''
    },
    next() {
      return this.current < this.pageCount
        ? this.getUrl(this.current + 1)
        : false
    },
    prev() {
      return this.current > 1 ? this.getUrl(this.current - 1) : false
    },
    pages() {
      const pages = []
      // 1 2 3 4 5
      if (
        this.pageCount <= this.showPages
        || this.showPagesCount + 2 > this.pageCount
      ) {
        for (let i = 1; i <= this.pageCount; i++) {
          pages.push(this.getData(i))
        }
      } else {
        // 1 [2] 3 ... 10

        if (this.current < this.showPagesCount) {
          for (let i = 1; i <= this.showPagesCount; i++) {
            pages.push(this.getData(i))
          }
          pages.push(this.createBreak(), this.getData(this.pageCount))
        } else if (this.current === this.showPagesCount) {
          // 1 2 [3] ... 10
          for (let i = 1; i <= this.showPagesCount + 1; i++) {
            pages.push(this.getData(i))
          }
          pages.push(this.createBreak(), this.getData(this.pageCount))
        } else {
          pages.push(this.getData(1))
          if (this.current >= 4) {
            pages.push(this.createBreak())
          }
          // 1 ... 17 18 19 [20]
          if (this.current === this.pageCount) {
            for (let i = this.current - 2; i <= this.current - 1; i++) {
              pages.push(this.getData(i))
            }
          } else if (this.current === this.pageCount - 1) {
            // 1 ...  8 [9] 10
            for (let i = this.current - 2; i <= this.current; i++) {
              pages.push(this.getData(i))
            }
          } else if (this.current === this.pageCount - 2) {
            // 1 ... 7 [8] 9 10
            for (let i = this.current - 1; i <= this.current + 1; i++) {
              pages.push(this.getData(i))
            }
          } else {
            // 1 ... 5 [6] 7 ... 10
            for (let i = this.current - 1; i <= this.current + 1; i++) {
              pages.push(this.getData(i))
            }
            pages.push(this.createBreak())
          }

          pages.push(this.getData(this.pageCount))
        }
      }
      return pages
    }
  },
  methods: {
    getUrl(number) {
      const pagePrefix = this.pagePrefix ? 'page/' : ''
      const pageLangPrefix = this.url.includes(this.urlLangPrefix)
        ? ''
        : this.urlLangPrefix

      const pageUrl
        = number === 1 ? this.url : `${this.url}${pagePrefix}${number}`

      return `${pageLangPrefix}${pageUrl}`
    },
    getData(index) {
      return {
        current: index === this.current,
        url: this.getUrl(index),
        index
      }
    },
    createBreak() {
      return {
        current: false,
        url: false,
        break: true,
        index: -1
      }
    },
    arrowClick(mode) {
      if (this.paginationType === 'virtual') {
        this.$emit(`get-${mode}-page`)
      }
    },
    pageLinkClick(pageNumber) {
      if (this.paginationType === 'virtual') {
        this.$emit('get-specific-page', pageNumber)
      }
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin: spacing(md) spacing(md) spacing(xl);
  padding-top: spacing(md);
  border-top: 1px solid color(wild-sand);

  &__pages {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    width: 30px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    line-height: 1;
    margin-inline: 4px;
    cursor: pointer;

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-weight: 500;
      transition: color 0.3s;
    }

    & button {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      height: 100%;
      width: 100%;
      color: hsl(0 0% 0% / 85%);
      transition: color 0.3s;
    }

    &:hover :is(a, button) {
      color: color(primary);
    }
  }

  &__item--separator span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__current {
    border: 2px solid color(primary);
    border-radius: 3px;
    font-weight: 700;

    & button {
      color: color(primary);
    }
  }

  &__btn {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover .svg-icon {
      fill: color(primary);
    }
  }

  &__next {
    padding-left: spacing(xs);
  }

  &__prev {
    padding-right: spacing(xs);
  }

  &__link {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    width: 100%;
    height: auto;
    max-width: 22px;
    max-height: 22px;

    & path {
      fill: hsl(0 0% 0% / 85%);
      transition: fill 0.3s;
    }

    &:hover path {
      fill: color(primary);
    }
  }
}
</style>
